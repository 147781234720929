export default {
  apply: {
    en: 'Apply Bonus',
    es: 'Aplicar bono',
  },
  freebet: {
    en: 'Freebet',
    es: 'Freebet',
  },
}
