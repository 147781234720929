export default {
  balance: {
    en: 'Balance',
    es: 'Balance',
  },
  inBets: {
    en: 'In bets',
    es: 'En apuestas',
  },
  toRedeem: {
    en: 'To redeem',
    es: 'Para redimir',
  },
}
