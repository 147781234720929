export default {
  title: {
    en: 'Odds Slippage Tolerance',
    es: 'Tolerancia de Deslizamiento de Cuotas',
  },
  custom: {
    en: 'Custom',
    es: 'Personalizado',
  },
}
