export default {
  betslip: {
    en: 'Betslip',
    es: 'Apuesta',
  },
  myBets: {
    en: 'My bets',
    es: 'Mis apuestas',
  },
}
