export default {
  locked: {
    en: 'Outcome removed or suspended',
    es: 'Resultado eliminado o suspendido',
  },
  possibleWin: {
    en: 'Possible win',
    es: 'Posible ganancia',
  },
}
